import React, { FunctionComponent } from "react";
import { MenuItem } from "../../utils/models";
import { Copyright, FooterContainer, StyledFooter} from "./style";
import '../../utils/font-awesome';

interface FooterProps {
  owner: string;
}

const Footer: FunctionComponent<FooterProps> = ({ owner }) => (
  <StyledFooter>
    <FooterContainer>
      <Copyright>
        <strong>{owner}</strong>&nbsp;&copy; {new Date().getFullYear()}
      </Copyright>
    </FooterContainer>
  </StyledFooter>
);

export default Footer;
