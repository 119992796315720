import styled, {css} from "styled-components";
import {Container} from "../../components/common";
import {Link} from "gatsby";
import Theme from "../../styles/theme";

export const StyledFooter = styled.footer`
  width: 100%;
  z-index: 700;
  position: absolute;
  bottom: 0;
  background-color: ${Theme.layout.secondaryColorDarker};
  width: 100%;
  padding: 10px 50px;
  > div {
    width: 100%;
  }

`;

export const FooterContainer = styled.div`
  text-align: left;
  justify-content: space-between;
  align-items: center;
`;

export const Copyright = styled.p`
  color: ${Theme.layout.white};
  text-align: right;
  width: 100%;
  display: inline-block;
  margin: 0;
  font-size: 0.9rem;

  strong {
    font-weight: 600;
  }
`;

export const StyledNav = styled.nav`
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    margin-right: 25px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const LinkStyle = css`
  color: ${Theme.layout.white};
  text-decoration: none;
`;

export const FooterMenuItem = styled.a`
  ${LinkStyle}
`;

export const FooterMenuLink = styled(Link)`
  ${LinkStyle}
`;
