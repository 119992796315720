import React, { FunctionComponent } from "react";
import { Nav, NavContainer, NavLink, NavMenu, NavMenuItem, NavWrapper, SearchContainer, SocialContainer } from "./style";
import { MenuItem } from "../../utils/models";
import { Search } from "../search";
import Logo from "../logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface NavigationProps {
  title: string;
  menu: MenuItem[];
  showSearch: boolean;
  dark?: boolean;
  fixedLogo?: boolean;
  bigHeader?: boolean;
}

const Navigation: FunctionComponent<NavigationProps> = ({ title, menu, dark = false, showSearch = true, fixedLogo = false, bigHeader = false }) => (
  <NavContainer dark={dark} fixed={fixedLogo}>
    <Nav>
      <Logo fixedLogo={fixedLogo} bigHeader={bigHeader} />
      <NavWrapper>
        {/* <NavMenu mobile={false}>
          {menu && menu.map((item, index) => (
            <NavMenuItem key={index}>
              <NavLink to={item.path} key={index}>{item.name}</NavLink>
            </NavMenuItem>
          ))}
        </NavMenu> */}
        <SearchContainer>
          <SocialContainer className='icons' href="tel:07960345747">
            <FontAwesomeIcon color="#fff" size="2x" icon={['fas', 'phone-square-alt']} />
          </SocialContainer>
          <SocialContainer className='icons' href='https://www.facebook.com/shepcontractws'>
            <FontAwesomeIcon color="#fff" size="2x" icon={['fab', 'facebook-square']} />
          </SocialContainer>
          <SocialContainer className='icons' href='https://www.instagram.com/shepcontractws/'>
            <FontAwesomeIcon color="#fff" size="2x" icon={['fab', 'instagram']} />
          </SocialContainer>
          {/* {showSearch &&
            <NavMenu>
              <Search />
            </NavMenu>
          } */}
        </SearchContainer>
      </NavWrapper>
    </Nav>
  </NavContainer>
);

export default Navigation;
