import {createGlobalStyle} from "styled-components";
import Theme from "./theme";
import styledNormalize from "styled-normalize";
import './global-style.css'

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}

  html {
    box-sizing: border-box;
  }

  body {
    font-family: ${Theme.fonts.base};
    line-height: 1.9em;
  }

  * {
    box-sizing: border-box;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${Theme.fonts.header};
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 100
  }

  a {
    color: #000;
    text-decoration: none;
  }

  .gatsby-highlight {
    max-width: 100% !important;
  }

  .gatsby-highlight-code-line {
    background-color: #353631;
    display: block;
    margin-right: -1em;
    margin-left: -1em;
    padding-right: 1em;
    padding-left: 0.75em;
  }

  @media (max-width: ${Theme.breakpoints.sm}) {
    h1, h2, h3, h4, h5, h6 {
      font-size: 1.1rem;
    }

    div, p {
      font-size: 0.85rem;
      line-height: 1.4rem;
    }
    
  }

`;

export default GlobalStyle;
