import styled from "styled-components";
import Theme from "../../styles/theme";
import Typed from "react-typed";
import { GatsbyImage } from "gatsby-plugin-image";

export const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
`

export const BackgroundImage = styled(GatsbyImage)`
background-position: center;
background-size: cover;
width: 100%;

`;

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  // @media (max-width: ${Theme.breakpoints.sm}) {
  //   margin-left: 0;
  //   margin-right: 0;
  //   text-align: center;
  //   overflow: hidden;
  // }
`;

export const TextWrapper = styled.div`
  position: absolute;
  transition: 0.3s;
  text-align: center;
  line-height: 1;
  font-family: '${Theme.fonts.header}';
  width: 33%;
  left: 50px;
    // :hover {
    //   transition: 0.3s;
    //   text-shadow:
    //     0px 0px 0 ${Theme.layout.shadow}, 
    //     0px 0px 0 ${Theme.layout.shadow}, 
    //     1px 1px 0 ${Theme.layout.shadow}, 
    //     2px 2px 0 ${Theme.layout.shadow},
    //     -2px -2px 10px black;
    // }

    // @media (max-width: ${Theme.breakpoints.sm}) {
    //   left: 25px;
    // }
    
`

export const TextWrapperContainer = styled.div`
  max-height: 800px;
  position: absolute;
  text-align: center;
  line-height: 1;
  top: 0;
  bottom: 0;
  z-index: 1;
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 33%, rgba(255, 255, 255, 1) 100%);
`

export const Title = styled.h1`
  font-size: 5vw;
  display: block;
  color: ${Theme.layout.white};
  align-items: center;
  display: flex;
  width: 100%;
  margin: 0;
  display: inline-block;

  // -webkit-text-stroke-width: 1px;
  // -webkit-text-stroke-color: ${Theme.layout.white};
  text-align: left;
  > span {
    color: ${Theme.layout.primaryColor};
    display: inline-block;
  }
  // @media (max-width: ${Theme.breakpoints.sm}) {
  //   font-size: 5vw;
  // }
`;

export const Description = styled.h2`
  margin: 0;
  color: ${Theme.layout.primaryColor};
  font-size: 3vw;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${Theme.layout.black};

    letter-spacing: 0.1em;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;

  // @media (max-width: ${Theme.breakpoints.sm}) {
  //   font-size: 4vw;
  //   -webkit-text-stroke-width: 1px;
  //   -webkit-text-stroke-color: black;
  // }
`;

export const StyledTopics = styled(Typed)`
  border-bottom: 3px #000 solid;
`;
