const Theme = {
  layout: {
    backgroundColor: `#fff`,
    primaryColorLight: `#9145c5`,
    primaryColor: `#682d91`,
    primaryColorDark: `#3e1b57`,
    secondaryColor: `#999999`,
    secondaryColorDarker: `#555555`,
    tertiaryColor: `#1b97a4`,
    black: `#191713`,
    black10Lighter: `#26201b`,
    white: `#fff`,
    linkColor: `#61c78c`,
    shadow: '#61c78c'

  },
  breakpoints: {
    xs: `500px`,
    sm: `576px`,
    md: `768px`,
    lg: `992px`,
    xl: `1300px`,
  },
  fonts: {
    base: `Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`,
    header: `Nunito-SemiBold`,
  },
  components: {
    container: {
      width: `1260px`,
    },
    header: {
      height: `400px`,
      background: `#999999`,
    },
  },
};

export default Theme;
