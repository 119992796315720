import styled from "styled-components";
import {Container} from "../common";
import Theme from "../../styles/theme";
import {Link} from "gatsby";

export const NavContainer = styled.div<{ dark?: boolean, fixed?: boolean}>`
  z-index: 1000;
  width: 100%;
  padding: 10px 50px;
  ${props => props.fixed === false && `
    position: absolute;
    @media (max-width: ${Theme.breakpoints.sm}) {
      position: inherit;
    }
  `};

  ${props => props.dark === true && `
    background-color: ${Theme.layout.secondaryColorDarker};
  `};

  @media (max-width: ${Theme.breakpoints.sm}) {
    // background-image: linear-gradient(to left, ${Theme.layout.black}, ${Theme.layout.black10Lighter});
    padding: 5px 25px;
  }
`;

export const Nav = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
`;


export const SocialContainer = styled.a`
  padding-right: 12px;
`;

export const NavWrapper = styled.div`
  white-space: nowrap;
`;

export const NavMenu = styled.ul<{ mobile?: boolean }>`
  align-self: center;
  list-style-type: none;
  margin: 0;
  padding: 0;

  ${props => props.mobile && `
    @media (max-width: ${Theme.breakpoints.sm}) {
      width: 50%;
      overflow-x: auto;
      overflow-y: hidden;
      mask-image: linear-gradient(to right, transparent, #000 25px, #000 90%, transparent);
    }
  `}
`;

export const NavMenuItem = styled.li`
  cursor: pointer;
  display: inline-block;
  border: 0;
  background: transparent;
  outline: none;
  text-decoration: none;
`;

export const NavLink = styled(Link)`
  color: #fff;
  opacity: .8;
  padding: 16px;
  transition: opacity .5s;

  &:hover {
    opacity: 1;
  }
`;

export const SearchContainer = styled.div`
  align-self: center;
  position: relative;
  display: inline-flex;
`;

export const ToggleSearchButton = styled.button`
  cursor: pointer;
  color: #fff;
  opacity: .8;
  background: none;
  outline: none;
  border: 0;
  transition: opacity .5s;

  &:hover {
    opacity: 1;
  }
`;
