import React, { FunctionComponent } from "react";
import { Description, StyledHeader, StyledTopics, Title, TitleWrapper, BackgroundImage, TextWrapper, TextWrapperContainer } from "./style";
import reactStringReplace from 'react-string-replace';
import { MenuItem } from "../../utils/models";
import { useStaticQuery, graphql } from "gatsby";

interface HeaderProps {
  title: string;
  description: string;
  topics: string[];
  bigHeader: boolean;
}


const Header: FunctionComponent<HeaderProps> = ({ title, description, topics = [] }) => {

  const backgroundImage = useStaticQuery(graphql`{
      file(sourceInstanceName: {eq: "assets"}, name: {eq: "cover"}) {
        childImageSharp {
          gatsbyImageData(height: 600, layout: FULL_WIDTH)
        }
      }
    }
  `);

  if (topics.length > 0) {
    description = reactStringReplace(description, '%TOPICS%', (match, i) => {
      return (
        <StyledTopics
          strings={topics}
          typeSpeed={50}
          backSpeed={60}
          shuffle={true}
          backDelay={1500}
          loop={true}
          key={match + i}
        />
      );
    }) as any;
  }

  return (
    <StyledHeader>
      <TitleWrapper>
        {/* <OfficeLogo>
          <OfficeSvg version="1.1" id="Capa_1" viewBox="0 0 512 512">
            <OfficePolygon points="320,0 32,112 32,416 128,384 128,128 320,96 320,449.408 32,416 320,512 480,464 480,48 "></OfficePolygon>
          </OfficeSvg>
        </OfficeLogo> */}
        <BackgroundImage image={backgroundImage.file.childImageSharp.gatsbyImageData} alt={'Cloud'} />
      </TitleWrapper>
    </StyledHeader>
  );
};

export default Header;
