import React, {FunctionComponent} from "react";
import styled from "styled-components";
import {graphql, Link, useStaticQuery} from "gatsby";
import Theme from "../../styles/theme";
import { GatsbyImage} from "gatsby-plugin-image";

interface LogoProps {
  fixedLogo?: boolean;
  bigHeader?: boolean;
}

const LogoImage = styled(GatsbyImage)`
  @media (max-width: ${Theme.breakpoints.sm}) {
    margin-right: 15px;
  }
`;

export const FeaturedImage = styled(GatsbyImage)`
  background-position: center;
  background-size: cover;
  max-width: 100%;
  border-top-left-radius: 3px;

  img {
    object-position: center left!important;
  }
`;

const HomeLink = styled(Link)`
  align-self: center;
  line-height: normal;
`;



const Logo: FunctionComponent<LogoProps> = ({fixedLogo = false, bigHeader=false}) => {
  const logo = useStaticQuery(graphql`{
    file(sourceInstanceName: {eq: "assets"}, name: {eq: "logo-all-white-cut"}) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, height: 80) 
      }
    }
  }
  `);

  return (
    <HomeLink to={`/`}>
      { !bigHeader && <GatsbyImage image={logo.file.childImageSharp.gatsbyImageData } alt="Shepherd Contracting" loading="eager"  /> }
    </HomeLink>
  );
}
;

export default Logo;
