import React, { FunctionComponent, ReactNode } from "react";
import GlobalStyle from "../styles/global-style";
import { graphql, useStaticQuery } from "gatsby";
import Header from "./header";
import { SiteMetadata } from "../utils/models";
import Navigation from "./navigation";
import Footer from "./footer";
import styled from "styled-components";
import CookieConsent from "react-cookie-consent";
import Theme from "../styles/theme";
import StyledNavigation from "./navigation";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette:
  {
    primary: {
      light: Theme.layout.primaryColorLight,
      main: Theme.layout.primaryColor,
      dark: Theme.layout.primaryColorDark,
      contrastText: "#fff"
    }
  },
})

interface LayoutProps {
  children: ReactNode;
  bigHeader?: boolean;
  fixedLogo?: boolean;
  dark?: boolean;
}

export const MainContainer = styled.main`
  padding-bottom: 100px;
  position: relative;
  @media (max-width: ${Theme.breakpoints.sm}) {
    padding-bottom: 150px;
  }
`;


export const HeaderLogo = styled.header`
  height: 50px;
  background-color: ${Theme.layout.secondaryColorDarker};
`;


export const LayoutContainer = styled.div`
  position: relative;
  min-height: 100vh;
  background-color: ${Theme.layout.backgroundColor};
`;

const Layout: FunctionComponent<LayoutProps> = ({ children, bigHeader = true, fixedLogo = false, dark = false }) => {
  const data = useStaticQuery<SiteMetadata>(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          topics
          search
        }
      }
    }
  `);

  return (
    <LayoutContainer>
      <StyledNavigation title={data.site.siteMetadata.title} menu={data.site.siteMetadata.menu} showSearch={data.site.siteMetadata.search} fixedLogo={true} bigHeader={bigHeader} dark={true} />
      <GlobalStyle />
      {bigHeader && (
        <div>
          <Header
            title={data.site.siteMetadata.title}
            description={data.site.siteMetadata.description}
            topics={data.site.siteMetadata.topics}
            bigHeader={bigHeader}
          />
        </div>
      )}
      <MuiThemeProvider theme={theme}>
        <MainContainer>
          {children}
        </MainContainer>
      </MuiThemeProvider>

      <Footer owner={data.site.siteMetadata.title} />
      <CookieConsent
        location="bottom"
        buttonText="Ok, understood"
        style={{ background: Theme.layout.black }}
        buttonStyle={{
          color: Theme.layout.white,
          backgroundColor: Theme.layout.primaryColor
        }}
        expires={365}
        cookieName="shepherd-contracting-cookies">
        We use cookies and other tracking technologies to improve your browsing experience on our website, to analyze our website traffic, and to understand where our visitors are coming from.
      </CookieConsent>
    </LayoutContainer>

  );
};

export default Layout;
